<template>
  <div class="payment-wrapper">
    <EmptyPaymentMethods />
    <UIButton :disabled="disabled" @click="emits('click')" type="tinted">
      <template #icon>
        <Plus />
      </template>
      Add a payment method
    </UIButton>
  </div>
</template>

<script setup lang="ts">
import Plus from '../assets/images/icons/plus.svg';
import EmptyPaymentMethods from '../assets/images/empty-payment-method.svg';
import UIButton from '../../../shared/UIButton.vue';

type EmptyPaymentMethodsEmits = {
  click: []
};

type EmptyPaymentMethodsProps = {
  disabled: boolean
};

defineProps<EmptyPaymentMethodsProps>();

const emits = defineEmits<EmptyPaymentMethodsEmits>();
</script>

<style scoped lang="scss">
@import '../assets/scss';

.payment-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 16px;
  gap: 12px;

  border: 1px dashed $--theme-blue-20-Light;
  border-radius: 8px;
}
</style>
