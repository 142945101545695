<template>
  <div class="date-delimiter">
    <UIMessageStatusBadge class="date-delimiter__badge">
      <template #label>{{ date.format('dddd, MMM D, YYYY') }}</template>
    </UIMessageStatusBadge>
  </div>
</template>

<script setup lang="ts">
import { UIMessageStatusBadge } from '@investorlift/www-investorlift-ui';
import moment from '../utils/moment';

type DateType = string | Date | moment.Moment;

const props = defineProps<{
    date: DateType
  }>(),
  date = moment(props.date);
</script>

<style lang="scss" scoped>
.date-delimiter {
  display: flex;
  width: 100%;
  justify-content: center;

  &__badge {
    background-color: #F2F2F2 !important;
    color: #999999 !important;
    padding: 4px 12px;
    border-radius: 20px;
  }
}
</style>
