<script setup lang="ts">
import { computed } from 'vue';

type UISwitchProps = {
  switched?: boolean
  clickable?: boolean
};

const { switched = null, clickable = true } = defineProps<UISwitchProps>();

const isSwitchedModel = defineModel<boolean>('switched');

const isSwitched = computed(() => switched ?? isSwitchedModel.value);

const handleSwitch = () => isSwitchedModel.value = !isSwitchedModel.value;
</script>

<template>
  <button
    role="switch"
    class="ui-switch"
    :data-clickable="clickable"
    :class="{ 'switched': isSwitched }"
    @click="handleSwitch"
  >
    <span class="switch-handler" />
  </button>
</template>

<style scoped lang="scss">
@import '../assets/scss/variables';

$switch-handler-size: 16px;
$switch-padding: 1px;

.ui-switch {
  position: relative;
  width: 36px;
  height: 20px;

  border-radius: 999px;
  border: 1px solid var(--Gray-15-Light, $--theme-gray-15-Light);
  background-color: var(--Gray-15-Light, $--theme-gray-15-Light);

  padding: 0;
  margin: 0;

  transition: all 100ms linear;
  cursor: pointer;

  &:disabled {
    border-color: var(--Gray-15-Light, $--theme-gray-15-Light);
    background-color: var(--Gray-10-Light, $--theme-gray-10-Light);

    pointer-events: none;
    cursor: default;

    .switch-handler {
      background-color: var(--Text-Light, $--theme-text-light);
    }
  }

  &:hover:not(:disabled) {
    border-color: var(--Gray-20-Light, $--theme-gray-20-Light);
    background-color: var(--Gray-20-Light, $--theme-gray-20-Light);
  }

  &[data-clickable="true"]:active {
    &.switched {
      .switch-handler {
        &:before {
          inset-inline-end: 0;
          inset-inline-start: -30%;
        }
      }
    }

    .switch-handler {
      &:before {
        inset-inline-end: -30%;
        inset-inline-start: 0;
      }
    }
  }

  &.switched {
    background-color: var(--50-Primary, $--theme-blue-50-Primary);
    border-color: var(--50-Primary, $--theme-blue-50-Primary);

    &:disabled {
      border-color: var(--Blue-25-Light, $--theme-blue-25-Light);
      background-color: var(--Blue-20-Light, $--theme-blue-20-Light);

      .switch-handler {
        background-color: var(--White, $--theme-white);
      }
    }

    &:hover:not(:disabled) {
      background-color: var(--Blue-40-Light, $--theme-blue-40-Light);
      border-color: var(--Blue-40-Light, $--theme-blue-40-Light);
    }

    .switch-handler {
      inset-inline-start: calc(100% - ($switch-handler-size + $switch-padding));
    }
  }

  .switch-handler {
    position: absolute;
    top: $switch-padding;

    inset-inline-start: $switch-padding;

    width: $switch-handler-size;
    height: $switch-handler-size;

    border-radius: 999px;
    background-color: var(--White, $--theme-white);

    transition: width 200ms cubic-bezier(0.16, 1, 0.3, 1), all 400ms cubic-bezier(0.16, 1, 0.3, 1);

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      inset-inline-end: 0;
      inset-inline-start: 0;
      background-color: inherit;
      border-radius: inherit;
      transition: inherit;
    }
  }
}
</style>
