import { computed, ref } from 'vue';
import type {
  ActiveSubscriptionsList,
  AvailableSubscriptionsList,
} from '../api/api';

export enum SUBSCRIPTION_EVENTS {
  OPEN_SUBSCRIPTION_PROMO_MODAL = 'open-subscription-promo-modal',
  SUBSCRIPTION_PURCHASED = 'subscription-purchased',
}

class SubscriptionsStore {
  public availableSubscriptions = ref<AvailableSubscriptionsList>([]);
  public activeSubscriptions = ref<ActiveSubscriptionsList>([]);

  public isActiveSubscriptionsExists = computed(() => this.activeSubscriptions.value.length !== 0);
}

const subscriptionsStore = new SubscriptionsStore();

export function useSubscriptionsStore() {
  return subscriptionsStore;
}
