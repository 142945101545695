<template>
  <div class="chat-frame" v-if="getActiveChatId">
    <div class="chat-frame__header" v-if="getActiveChatId">
      <Avatar class="chat-frame__avatar">
        <img v-if="getActiveChat?.logo" :src="getActiveChat?.logo"/>
        <template v-else>{{ getNameAbbreviation(getActiveChat?.name) }}</template>
      </Avatar>
      <div class="chat-frame__header-body">
        <div class="chat-frame__recipient">{{ getActiveChat?.name }}</div>
        <div class="chat-frame__name"></div>
      </div>
      <ActionButton :on-click="onContextMenuClick" style="display: none"/>
    </div>

    <div
      class="chat-frame__body"
      v-if="messageList.length"
      ref="container"
      @scroll="onScroll"
    >
      <template
        v-for="(message, index) in messageList || []"
        :key="message?.id"
      >
        <MessageItem
          :id="message?.id"
          :type="message?.type"
          :name="getActiveChat?.name"
          :date="message?.createdAt"
          :unread="!message?.isRead"
        >
          <template #badge>
            <StatusBadge :type="message['action']" />
            <StatusBadge v-if="message['action'] === 'blast'" :type="message?.channel" />
          </template>
          <template #default>
            <Offer
              v-if="message?.action === 'offer' && message?.offer"
              :price="message?.offer?.['price']"
              :name="message?.offer?.['buyer']"
            />
            <p v-if="isMessageEnabled(message)">{{ message?.message }}</p>
          </template>
          <template #attachment v-if="message['property']">
            <Property
              :id="message['property']?.id"
              :date="message['property']?.createdAt"
              :url="message['property']?.url"
              :thumb="message['property']?.thumb"
              :price="message['property']?.price"
              :arv="message['property']?.arv"
              :address="message['property']?.address"
              :beds="message['property']?.beds"
              :baths="message['property']?.baths"
              :sqft="message['property']?.sqft"
            />
          </template>
        </MessageItem>
        <DateDelimiter
          v-if="!isSameDate(message?.createdAt, messageList?.[index + 1]?.createdAt)"
          :date="message?.createdAt"
        />
      </template>
    </div>
    <div class="chat-frame__body" v-else>
      <MessageItem type="inbound" date="" skeleton />
      <MessageItem type="inbound" date="" skeleton />
      <MessageItem type="outbound" date="" skeleton />
      <MessageItem type="outbound" date="" skeleton />
      <MessageItem type="inbound" date="" skeleton />
    </div>
    <div class="chat-frame__footer">
      <ActionButton
        :on-click="onSmileClick"
        class="chat-frame__btn chat-frame__btn--smile"
        style="display: none"
      >
        <IconSmile/>
      </ActionButton>
      <div class="chat-frame__message">
        <UIInput
          placeholder="Type a message"
          class="chat-frame__input"
          :value="message"
          @input="onInput"
          @keydown.enter="onSendClick"
        >
          <template v-if="overflow" #slotRight>
            <span class="chat-frame__input-overflow">-{{ overflow }}</span>
          </template>
        </UIInput>
      </div>
      <ActionButton
        type="gray"
        :on-click="onSendClick"
        :class="['chat-frame__btn', 'chat-frame__btn--send', { 'chat-frame__btn--disabled': isDisabled }]"
      >
        <IconSend/>
      </ActionButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import IconSend from '../assets/images/icons/send.svg';
import IconSmile from '../assets/images/icons/smile.svg';

import { computed, ref } from 'vue';
import moment from '../utils/moment';
import debounce from '../utils/debounce';
import { UIInput } from '@investorlift/www-investorlift-ui';
// NOTE: UIButton is not production-ready and not compliant with design
import ActionButton from './ActionButton.vue';
import Avatar from './Avatar.vue';
import DateDelimiter from './DateDelimiter.vue';
import MessageItem from './MessageItem.vue';
import Offer from './Offer.vue';
import Property from './Property.vue';
import StatusBadge from './StatusBadge.vue';
import { type Message, useChatStore } from '../stores/chat';

const container = ref<HTMLElement | null>(null);
const {
  getActiveChat,
  getActiveChatId,
  messageList,
  fetchMessageList,
  publishMessage,
} = useChatStore();

const MESSAGE_LIMIT = 1000;
const OFFSET_THRESHOLD = 300;

const message = ref('');
const overflow = ref(0);
const isDisabled = computed(() => !message.value || !!overflow.value);

const getNameAbbreviation = (name?: string) => {
  return name?.[0]?.toUpperCase?.() || '#';
};

const isSameDate = (date1: string | Date | moment.Moment, date2: string | Date | moment.Moment | undefined) => {
  return moment(date1).isSame(date2, 'day');
};

const isMessageEnabled = (message: Message) => {
  const isEmptyBlast = (message?.action === 'blast') && (message?.message === 'Blast');

  return !isEmptyBlast;
};

const onInput = (e: InputEvent) => {
  const input = (e.target as HTMLInputElement).value;

  message.value = input;
  overflow.value = input.length > MESSAGE_LIMIT ? input.length - MESSAGE_LIMIT : 0;
};

const onContextMenuClick = () => {
  console.log('Context menu button clicked');
};

const onSendClick = () => {
  if (message.value && !overflow.value) {
    publishMessage(message.value);
    message.value = '';
  }
};

const onSmileClick = () => {
  console.log('Smile button clicked');
};

// NOTE:
// There is a potential usecase, when preloaded and rendered messages fit exact the container without appearing scroll,
// but the chat still has extra pages to load
// In this case, the scroll event will not be triggered, and the user will not be able to load more messages
// Using onUpdated() to load more messages looks not optimal
const onScroll = debounce(() => {
  const target = container.value as HTMLElement; // NOTE: e.target is not working in shadow dom for webcomponents
  const offset = target?.scrollTop + target?.scrollHeight - target?.clientHeight;

  if (offset <= OFFSET_THRESHOLD) fetchMessageList();
});
</script>

<style lang="scss" scoped>
.chat-frame {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &__header {
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    align-items: center;
    padding: 12px 16px;
    border-bottom: 1px solid #E5E5E5;

    &-body {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
    }
  }

  &__body {
    display: flex;
    flex-direction: column-reverse;
    gap: 16px;
    flex: 1;
    width: 100%;
    padding: 16px;
    overflow-y: auto;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
    padding: 16px;
    border-top: 1px solid #E5E5E5;
  }

  &__avatar {
    width: 38px;
    height: 38px;
  }

  &__recipient {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: #1A1B22;
  }

  &__name {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    color: #4F5366;
  }

  &__btn {
    width: 44px;
    height: 44px;

    &--smile :deep(svg path) {
      stroke: #4F5366;
    }

    &--send :deep(svg path) {
      stroke: #2958FF;
    }

    &--disabled :deep(svg path) {
      stroke: #A7A9B3;
    }
  }

  &__message {
    flex: 1;
  }

  &__input {
    width: 100%;

    &-overflow {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: #D11818;
    }
  }
}
</style>
