<template>
  <div class="messenger-action-button" v-if="isAuthorized">
    <a :href="props.href" class="messenger-action-button__btn">
      <span class="messenger-action-button__label">Messages</span>
      <span v-if="!!unreadCount" class="messenger-action-button__badge">{{ unreadCount }}</span>
    </a>
  </div>
</template>

<script setup lang="ts">
import type { PusherConfig, StringifiedPusherConfig } from '../stores/chat';
import { onMounted, watch } from 'vue';
import { useUserStore } from '../stores/user';
import { useChatStore } from '../stores/chat';
import { controllerMount, controllerUnmount } from '../utils/controller';

interface MessengerActionButtonProps {
  href: string
  jwt: string
  api: string
  pusher: PusherConfig | StringifiedPusherConfig
}

const props = defineProps<MessengerActionButtonProps>();

const { isAuthorized } = useUserStore();
const { unreadCount } = useChatStore();

// an attempt to initialize the store on component mount
onMounted(async () => {
  const { jwt, api, pusher } = props;

  await controllerMount(jwt, api, pusher);
});

// watch for jwt changes, and reinitialize the store if jwt is present
watch(
  () => props.jwt,
  async () => {
    const { jwt, api, pusher } = props;

    if (jwt) await controllerMount(jwt, api, pusher);
    else controllerUnmount();
  },
);
</script>

<style lang="scss" scoped>
.messenger-action-button {
  display: flex;
  justify-content: center;
  align-items: center;

  &__btn {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    gap: 3px;
    width: 100%;
    height: 100%;
    max-width: 142px;
    max-height: 49px;
    border: 1px solid #274ABB;
    border-radius: 3px;
    padding: 11px 20px;
    cursor: pointer;
    background-color: #FFFFFF;
    text-decoration: none;

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #FFFFFF;
      opacity: 0;
    }

    &:hover:before {
      opacity: 0.1;
    }
  }

  &__label {
    flex: none;
    font-weight: 400;
    font-size: 15px;
    line-height: 27px;
    color: #274ABB;
  }

  &__badge {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: none;
    min-width: 16px;
    height: 16px;
    padding: 1px 4px;
    border-radius: 8px;
    background-color: #D62F2F;
    color: #FFFFFF;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

  }
}
</style>
