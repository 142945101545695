import axios, { type AxiosInstance } from 'axios';
import formatPrice from '../utils/formatPrice';
import formatBillDate from '../utils/formatBillDate';

export enum SUBSCRIPTION_PLANS {
  DEALS_VIP = 'deals-vip',
  DEFAULT_SUBSCRIPTION = 'CUSTOMER_ACCOUNT_TYPE',
}

type ApiConfig = {
  jwt: string
  api: string
};

type PurchaseSubscriptionDto = {
  plan: SUBSCRIPTION_PLANS
  quantity: 1
  start_subscription: 'immediately'
  starts_at: string | null
};

type AvailableSubscriptionRaw = {
  id: string
  slug: SUBSCRIPTION_PLANS
  description: string
  price: string
  discount?: number
  auto_renew: boolean
  finish_at: string
};

type ActiveSubscriptionsResponse = {
  operation: AvailableSubscriptionRaw
  recurly_entity_id: string
  auto_renew: boolean
  finish_at: string
}[];

type AvailableSubscriptionsResponse = AvailableSubscriptionRaw[];

export type ActiveSubscription = {
  id: string
  plan: SUBSCRIPTION_PLANS
  description: string
  price: string
  discount?: number
  renewAt?: string
  isAutoRenew: boolean
};

export type AvailableSubscription = {
  id: string
  plan: SUBSCRIPTION_PLANS
  price: string
  description: string
  discount?: number
};

export type AvailableSubscriptionsList = AvailableSubscription[];

export type ActiveSubscriptionsList = ActiveSubscription[];

class Api {
  private axios: null | AxiosInstance = null;

  private token: string = '';
  private baseUrl: string = '';
  private isInitialized: boolean = false;

  public init = (config: ApiConfig) => {
    if (this.isInitialized) return;

    this.isInitialized = true;
    this.token = config.jwt;
    this.baseUrl = config.api;

    this.initJobs();
  };

  public update = (config: ApiConfig) => {
    this.token = config.jwt;
    this.baseUrl = config.api;
    this.initJobs();
  };

  private initJobs = () => {
    this.axios = axios.create({
      baseURL: this.baseUrl,
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  private transformActiveSubscription = (data: ActiveSubscriptionsResponse): ActiveSubscription[] => {
    return data
      .map(activeSubscription => ({
        id: activeSubscription.recurly_entity_id,
        plan: activeSubscription.operation.slug,
        description: activeSubscription.operation.description,
        price: formatPrice(activeSubscription.operation.price),
        discount: activeSubscription.operation.discount,
        isAutoRenew: activeSubscription.auto_renew,
        ...(activeSubscription.finish_at && { renewAt: formatBillDate(activeSubscription.finish_at) }),
      }))
      .filter(data => data.plan !== SUBSCRIPTION_PLANS.DEFAULT_SUBSCRIPTION);
  };

  private transformAvailableSubscription = (data: AvailableSubscriptionRaw[]): AvailableSubscription[] => {
    return data.map(availableSubscription => ({
      id: availableSubscription.id,
      plan: availableSubscription.slug,
      description: availableSubscription.description,
      price: formatPrice(availableSubscription.price),
      discount: availableSubscription.discount,
    }));
  };

  public getSubscriptions = async () => {
    const url = '../../api/storefront';

    try {
      const response = await this.axios?.get(url) || null;
      const data: AvailableSubscriptionsResponse = response?.data;
      return this.transformAvailableSubscription(data);
    }
    catch (e) {
      console.warn('Something went wrong fetching subscriptions', e);
    }
  };

  public getActiveSubscriptions = async () => {
    const url = '../../api/document?with=operation&filter[mode]=my_subscriptions';

    try {
      const response = await this.axios?.get(url) || null;
      const data: ActiveSubscriptionsResponse = response?.data;
      return this.transformActiveSubscription(data);
    }
    catch (e) {
      console.warn('Something went wrong fetching active subscriptions', e);
    }
  };

  public purchaseSubscription = async (plan: SUBSCRIPTION_PLANS) => {
    const url = '../../api/recurly-accounts/subscriptions';

    const purchaseSubscriptionDto: PurchaseSubscriptionDto = {
      plan,
      quantity: 1,
      start_subscription: 'immediately',
      starts_at: null,
    };

    try {
      const response = await this.axios?.post(url, purchaseSubscriptionDto) || null;
      const data: ActiveSubscriptionsResponse = response?.data;
      return this.transformActiveSubscription(data)[0];
    }
    catch (e) {
      console.warn('Something went wrong purchasing subscription', e);
    }
  };

  public cancelSubscription = async (id: string) => {
    const url = '../../api/recurly-accounts/subscriptions/cancel';

    try {
      const response = await this.axios?.post(url, { id }) || null;
      const data: ActiveSubscriptionsResponse = response?.data;
      return this.transformActiveSubscription(data);
    }
    catch (e) {
      console.warn('Something went wrong canceling subscription', e);
    }
  };

  public reactivateSubscription = async (id: string) => {
    const url = '../../api/recurly-accounts/subscriptions/reactivate';

    try {
      const response = await this.axios?.post(url, { id }) || null;
      const data: ActiveSubscriptionsResponse = response?.data;
      return this.transformActiveSubscription(data);
    }
    catch (e) {
      console.warn('Something went wrong reactivating subscription', e);
    }
  };
}

export const {
  getSubscriptions,
  purchaseSubscription,
  getActiveSubscriptions,
  cancelSubscription,
  reactivateSubscription,
  init,
  update,
} = new Api();
