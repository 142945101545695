<template>
  <div class="subscription-card">
    <slot />
    <h3 class="heading">{{ title }}</h3>
    <UIButton @click="handleSubscriptionModalOpen" class="btn">{{ buttonText }}</UIButton>
    <p class="details">{{ details }}</p>
  </div>
  <AddPaymentMethodModalForm
    v-model:open="isAddPaymentMethodModalOpen"
    @submit="handlePaymentMethodSubmit"
  />
  <SubscriptionPurchaseModal
    trial
    :billing-start="billingStart"
    :price="price"
    :disabled="isCheckoutDisabled"
    :loading="isCheckoutDisabled"
    v-model:open="isSubscriptionPurchaseModalOpen"
    @payment-method="handleOpenAddPaymentMethodModal"
    @checkout="handleCheckout"
  />
</template>

<script setup lang="ts">
import UIButton from '../../../shared/UIButton.vue';
import SubscriptionPurchaseModal from './SubscriptionPurchaseModal.vue';
import AddPaymentMethodModalForm from '../../payment-methods/components/AddPaymentMethodModalForm.vue';
import { purchaseSubscription, SUBSCRIPTION_PLANS } from '../api/api';
import { computed, ref } from 'vue';
import moment from '../../messenger/utils/moment';
import formatBillDate from '../utils/formatBillDate';
import { SUBSCRIPTION_EVENTS, useSubscriptionsStore } from '../stores/subscriptions';
import { usePaymentMethodsStore } from '../../payment-methods/stores/paymentMethods';

type AvailableSubscriptionCardProps = {
  title: string
  buttonText: string
  details: string
  plan: SUBSCRIPTION_PLANS
  price: string
};

const { plan } = defineProps<AvailableSubscriptionCardProps>();

const emit = defineEmits([SUBSCRIPTION_EVENTS.SUBSCRIPTION_PURCHASED]);

const { activeSubscriptions } = useSubscriptionsStore();
const { paymentMethods } = usePaymentMethodsStore();

const isSubscriptionPurchaseModalOpen = ref(false);
const isAddPaymentMethodModalOpen = ref(false);
const isCheckoutDisabled = ref(false);

const billingStart = computed(() => formatBillDate(moment().add(1, 'month')));

const handleCheckout = async () => {
  isCheckoutDisabled.value = true;

  const purchasedSubscription = await purchaseSubscription(plan);

  if (purchasedSubscription) {
    emit(SUBSCRIPTION_EVENTS.SUBSCRIPTION_PURCHASED, plan);
    activeSubscriptions.value.push(purchasedSubscription);
  }

  isSubscriptionPurchaseModalOpen.value = false;
  isCheckoutDisabled.value = false;
};

const handleSubscriptionModalOpen = () =>
  isSubscriptionPurchaseModalOpen.value = true;

const handleOpenAddPaymentMethodModal = () => {
  isSubscriptionPurchaseModalOpen.value = false;
  isAddPaymentMethodModalOpen.value = true;
};

const handlePaymentMethodSubmit = () => {
  const isPaymentMethodAdded = paymentMethods.value.length !== 0;

  if (isPaymentMethodAdded) {
    isSubscriptionPurchaseModalOpen.value = true;
  }
};

</script>

<style scoped lang="scss">
@import '../assets/scss';

.subscription-card {
  display: grid;
  gap: 16px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid var(--Blue-10-Light, $--theme-blue-10-Light);
  background-color: var(--White, $--theme-white);

  .heading {
    color: var(--Text-Default, $--theme-text-default);
    @include text-18-700;
  }

  .btn {
    max-width: max-content;
  }

  .details {
    @include text-14-400;
    color: var(--Text-Medium, $--theme-text-medium);
  }
}
</style>
