<template>
  <div :class="['message-item', `message-item--${type}`]">
    <div v-if="props.type === MessageType.OUTBOUND" class="message-item__sender">
      <Avatar class="message-item__sender-avatar" v-if="!skeleton">
        {{ getNameAbbreviation(props.name) }}
      </Avatar>
      <Skeletor
        v-else
        circle
        size="32"
      />
    </div>
    <div class="message-item__reply" style="display: none">
      <a
        v-if="!skeleton"
        href="javascript:void(0)"
        class="message-item__reply-btn"
        @click.prevent="onClick"
      >
        <IconArrow/>
      </a>
      <Skeletor
        v-else
        circle
        size="24"
      />
    </div>
    <div class="message-item__body" v-if="!skeleton">
      <div class="message-item__header">
        <div class="message-item__header-badge">
          <slot name="badge"></slot>
        </div>
        <div class="message-item__header-status" v-if="props.unread && props.type === MessageType.OUTBOUND">
          <UIBadgeNotification state="error" size="xs" />
        </div>
        <div class="message-item__header-date">
          {{ date.format('h:mm A') }}
        </div>
      </div>
      <div class="message-item__text">
        <slot name="default"></slot>
      </div>
      <div v-if="$slots.attachment" class="message-item__attachment">
        <slot name="attachment"></slot>
      </div>
    </div>
    <Skeletor
      v-else
      class="message-item__body"
      height="100"
    />
  </div>
</template>

<script setup lang="ts">
import IconArrow from '../assets/images/icons/arrow-down-left.svg';

import { onMounted } from 'vue';
import { Skeletor } from 'vue-skeletor';

import moment from '../utils/moment';
import { UIBadgeNotification } from '@investorlift/www-investorlift-ui';
import Avatar from './Avatar.vue';

import { MessageType, useChatStore } from '../stores/chat';

type MessageDirection = 'inbound' | 'outbound';
type DateType = string | Date | moment.Moment;

const READ_TIMEOUT = 1000;

const props = defineProps<{
    id?: any
    type: MessageType | MessageDirection // NOTE: the direction is valid for Admin, so for Deals it's the contrary
    date: DateType
    name?: string
    skeleton?: boolean
    unread?: boolean
  }>(),
  date = moment(props.date);

const { readMessage } = useChatStore();

const getNameAbbreviation = (name?: string) => {
  return name?.[0]?.toUpperCase?.() || '#';
};

const onClick = () => {
  console.log('Reply button clicked');
};

onMounted(() => {
  if (props.unread && props.type === MessageType.OUTBOUND && props.id) {
    setTimeout(() => readMessage(props.id), READ_TIMEOUT);
  }
});
</script>

<style lang="scss" scoped>
@import 'vue-skeletor/dist/vue-skeletor.css';

.message-item {
  display: flex;
  width: 100%;
  gap: 8px;

  &__sender {
    display: flex;
    justify-content: center;
    padding: 8px 0;

    &-avatar {
      width: 32px !important;
      height: 32px !important;
    }
  }

  &__reply {
    display: flex;
    justify-content: center;
    align-items: center;

    &-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      padding: 4px;
      background-color: #F2F2F2;
    }
  }

  &--outbound {
    justify-content: flex-start;

    .message-item__reply {
      order: 2;
    }
  }

  &--inbound {
    justify-content: flex-end;

    .message-item__reply {
      order: 0;
    }
  }

  &__body {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 12px;
    gap: 12px;
    border-radius: 12px;
    border: 1px solid #E5E5E5;
    width: calc(100% - 164px);
    max-width: 70%;
  }

  &__header {
    display: flex;
    align-items: center;
    column-gap: 8px;

    &-badge {
      display: flex;
      gap: 4px;
      flex: auto;
    }

    &-status {
      flex: none;
    }

    &-date {
      flex: none;
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      color: #A7A9B3;
    }
  }

  &__text {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #1A1B22;
  }
}

.vue-skeletor {
  background-color: #F2F2F2 !important;
  border: 0 !important;
}
</style>
