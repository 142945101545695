<template>
  <main class="payment-method-container">
    <div class="container">
      <header class="header">
        <h1 class="heading">Payment methods</h1>
        <p class="sub-heading">{{ subHeading }}</p>
      </header>
      <div class="content">
        <template v-if="isPaymentMethodsExists">
          <paymentMethod
            v-for="paymentMethod in paymentMethods"
            :key="paymentMethod.id"
            :data="paymentMethod"
            @edit="handleAddPaymentMethodModalOpen"
          />
        </template>
        <EmptyPaymentMethods v-else :disabled="isAddPaymentDisabled" @click="handleAddPaymentMethodModalOpen" />
        <AddPaymentMethodModalForm v-model:open="isAddPaymentMethodModalOpen" />
      </div>
    </div>
  </main>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue';
import AddPaymentMethodModalForm from './AddPaymentMethodModalForm.vue';
import EmptyPaymentMethods from './EmptyPaymentMethods.vue';
import PaymentMethod from './PaymentMethod.vue';
import { init, getPaymentMethods } from '../api/api';
import { usePaymentMethodsStore } from '../stores/paymentMethods';

type PaymentMethodsProps = {
  jwt: string
  api: string
};

const { jwt, api } = defineProps<PaymentMethodsProps>();

init({
  jwt,
  api: api,
});

const { paymentMethods, isPaymentMethodsExists } = usePaymentMethodsStore();

const isAddPaymentMethodModalOpen = ref(false);
const isAddPaymentDisabled = ref(false);

const subHeading = computed(() =>
  paymentMethods.value
    ? 'Your saved payment methods for easy checkout'
    : 'You don’t have any saved payment methods',
);

const handleAddPaymentMethodModalOpen = () => {
  isAddPaymentMethodModalOpen.value = true;
};

onBeforeMount(async () => {
  isAddPaymentDisabled.value = true;

  const methods = await getPaymentMethods();

  if (methods) {
    paymentMethods.value = methods;
  }

  isAddPaymentDisabled.value = false;
});
</script>

<style>
@import '../assets/scss/_index.scss';
</style>

<style scoped lang="scss">
@import '../assets/scss';

.payment-method-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  .container {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;
    max-width: 1018px;

    border-radius: 12px;
    border: 1px solid $--theme-gray-10-Light;

    .header {
      width: 100%;
      padding: 16px 24px;

      border-bottom: 1px solid $--theme-gray-10-Light;

      .heading {
        color: var(--Text-Default, $--theme-text-default);
        @include text-18-600;
      }

      .sub-heading {
        color: var(--Text-Medium, $--theme-text-medium);
        @include text-13-400;
      }
    }

    .content {
      width: 100%;
      height: 100%;
      padding: 16px 24px;
    }
  }
}
</style>
