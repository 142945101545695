<script setup lang="ts">
import SpinnerIcon from '../assets/icons/spinner.svg';
import { computed } from 'vue';

type UIButtonTypes = 'filled' | 'outline' | 'tinted' | 'grey' | 'plain';

type UIButtonSizes = 'xl' | 'lg' | 'md' | 'sm' | 'xs';

type UIButtonStyles = 'primary' | 'gray' | 'black' | 'red';

type UIButtonProps = {
  type?: UIButtonTypes
  size?: UIButtonSizes
  as?: UIButtonStyles
  loading?: boolean
  disabled?: boolean
};

type UIButtonEmits = {
  click: []
};

const emits = defineEmits<UIButtonEmits>();

const props = withDefaults(
  defineProps<UIButtonProps>(),
  {
    type: 'filled',
    size: 'md',
    as: 'primary',
    loading: false,
  },
);

const isDisabled = computed(() => props.loading || props.disabled);
</script>

<template>
  <button
    class="ui-button"
    :disabled="isDisabled"
    :class="[ props.type, props.size, props.as ]"
    @click="emits('click')"
  >
    <slot name="icon" />
    <SpinnerIcon class="spinner" v-if="loading" />
    <span v-if="!loading" class="label">
      <slot />
    </span>
  </button>
</template>

<style scoped lang="scss">
@import '../assets/scss/variables';
@import '../assets/scss/mixins';

@keyframes spinner-animation {
  0% {
    rotate: 0deg;
  }
  100% {
    rotate: 360deg;
  }
}

.ui-button {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 8px;
  border: 1px solid;

  @include text-15-500;

  cursor: pointer;

  padding: 11px;
  min-height: 46px;

  transition: all 300ms ease;

  color: $--theme-gray-80-Light;
  text-align: center;

  &:is(&:focus) {
    outline: none;
    box-shadow: 0 0 0 3px $--theme-blue-35-Light;
  }

  &:is(&:disabled),
  &:is(&:disabled):hover {
    background-color: $--theme-gray-15-Light;
    border-color: $--theme-gray-05-Light;
    color: $--theme-text-light;
    cursor: default;
  }

  .label {
    padding: 0 8px;
  }

  .spinner {
    animation: spinner-animation 1s linear infinite;
  }

  &.xl {
    @include text-18-500;
  }

  &.lg {
    @include text-16-500;
  }

  &.md {
    min-height: unset;
    padding: 8px;
    border-radius: 6px;
    @include text-14-500;

    .label {
      padding: 0 6px;
    }
  }

  &.sm {
    min-height: unset;
    padding: 8px;
    border-radius: 6px;
    @include text-12-500;

    .label {
      padding: 0 6px;
    }
  }

  &.xs {
    @include text-10-500;
  }

  &.primary {
    &:where(&.filled) {
      border-color: $--theme-blue-50-Primary;
      background-color: $--theme-blue-50-Primary;
      color: $--theme-white;

      &:hover {
        background-color: $--theme-blue-60-Dark;
        border-color: $--theme-blue-60-Dark;
      }
    }

    &:where(&.outline) {
      border-color: $--theme-blue-50-Primary;
      color: $--theme-blue-50-Primary;
      background-color: transparent;

      &:hover {
        background-color: $--theme-blue-05-Light;
      }
    }

    &:where(&.tinted) {
      border-color: $--theme-blue-05-Light;
      background: $--theme-blue-05-Light;
      color: $--theme-blue-50-Primary;

      &:hover {
        background-color: $--theme-blue-15-Light;
      }
    }

    &:where(&.plain) {
      border-color: transparent;
      background: transparent;
      color: $--theme-blue-50-Primary;

      &:hover {
        background-color: $--theme-gray-05-Light;
        border-color: $--theme-gray-05-Light;
      }
    }
  }

  &.gray {
    &:where(&.grey) {
      border-color: $--theme-gray-05-Light;
      background-color: $--theme-gray-05-Light;

      &:hover {
        border-color: $--theme-gray-10-Light;
        background-color: $--theme-gray-10-Light;
      }
    }
  }

  &.red {
    &:where(&.tinted) {
      border-color: #FDECEC;
      background-color: #FDECEC;
      color: $--theme-danger-55-Danger;

      &:hover {
        border-color: $--theme-danger-10-Danger;
        background-color: $--theme-danger-10-Danger;
      }
    }
  }
}
</style>
