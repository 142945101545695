<script setup lang="ts">
import { onMounted, onUnmounted, useTemplateRef, watch } from 'vue';
import { UICloseButton } from '@investorlift/www-investorlift-ui';

type UIModalProps = {
  title?: string
  class?: string
};

const { title, class: className } = defineProps<UIModalProps>();

const openModel = defineModel<boolean>('open', { required: true });

const emit = defineEmits<{
  opened: []
  closed: []
}>();

const dialog = useTemplateRef('dialog');

const handleDialogClose = () => {
  dialog.value?.classList.add('fade-out');
  dialog.value?.addEventListener('animationend', () => {
    dialog.value?.classList.remove('fade-out');
    dialog.value?.close();
    emit('closed');
  }, { once: true });
};

const handleDialogOpen = () => {
  emit('opened');
  dialog.value?.showModal();
};

watch(openModel, (isOpen) => {
  if (isOpen) {
    handleDialogOpen();
  }
  else {
    handleDialogClose();
  }
});

const handleOutsideClick = (e: MouseEvent) => {
  if (e.target !== dialog.value) return;

  const target = e.target as HTMLElement;
  const rect = target.getBoundingClientRect();

  const isClickedInsideDialog = (
    rect.top <= e.clientY
    && e.clientY <= rect.top + rect.height
    && rect.left <= e.clientX
    && e.clientX <= rect.left + rect.width
  );

  if (!isClickedInsideDialog) {
    openModel.value = false;
  }
};

onMounted(() => {
  document.addEventListener('click', handleOutsideClick, true);
});

onUnmounted(() => {
  document.removeEventListener('click', handleOutsideClick);
});
</script>

<template>
  <dialog
    class="ui-modal"
    :class="className"
    ref="dialog"
    @keydown.esc.exact.prevent="openModel = false"
  >
    <div class="header">
      <slot name="header-icon" />
      <h3 class="title" v-if="title">{{ title }}</h3>
      <UICloseButton @click="openModel = false" class-name="close-button" />
    </div>
    <div class="body">
      <slot />
    </div>
  </dialog>
</template>

<style scoped lang="scss">
@import '../assets/scss/variables';
@import '../assets/scss/mixins';

@keyframes modal-fade-in {
  0% {
    opacity: 0;
    scale: 0.985;
  }
  80% {
    opacity: 1;
  }
  100% {
    scale: 1;
  }
}

@keyframes modal-fade-out {
  100% {
    opacity: 0;
    scale: 0.985;
  }
}

@keyframes backdrop-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes backdrop-fade-out {
  100% {
    opacity: 0;
  }
}

.ui-modal {
  border-radius: 12px;
  border: 1px solid $--theme-gray-15-Light;
  overflow-y: auto !important;

  padding: 0;

  animation: modal-fade-in 400ms $ease-out-quart both;

  &::backdrop {
    background-color: rgba(0, 0, 0, 0.52);
  }

  &[open]::backdrop {
    animation: backdrop-fade-in 300ms both;
  }

  &.fade-out {
    animation: modal-fade-out 150ms $ease-in-cubic both;

    &::backdrop {
      animation: backdrop-fade-out 150ms both;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 1;

    padding: 16px;

    border-bottom: 1px solid $--theme-gray-15-Light;

    .title {
      @include text-16-600;
      color: $--theme-gray-75-Light;
      height: max-content;
      padding: 0 8px;
      margin: 0;
    }

    .close-button {
      box-sizing: border-box;

      height: 38px;
      width: 38px;

      padding: 8px;
      border: none;

      transition: all 300ms ease;

      &:not(:hover) {
        background-color: transparent;
      }

      &:deep(svg) {
        box-sizing: border-box;
        width: 100%;
        height: 100%;

        padding: 5px;
      }
    }
  }

  .body {
    display: grid;
    gap: 24px;
    padding: 24px;
  }
}
</style>
