<template>
  <UIModal v-model:open="isModalOpenModel" class="modal" title="Complete your purchase">
    <div class="row">
      <PremiumBuyerLabel size="lg" />
      <span v-if="trial" class="price">Free trial</span>
    </div>
    <div class="row">
      <div>
        <h4 class="bill-title">Monthly charge</h4>
        <p class="bill-start">
          Billing starts:
          <time :datetime="billingStart">{{ billingStart }}</time>
        </p>
      </div>
      <span class="price">{{ price }}</span>
    </div>
    <div class="payment-method-required">
      <div v-if="isPaymentMethodsExists && primaryPaymentMethod" class="payment-method-wrapper">
        <CreditCardIcon :type="primaryPaymentMethod.cardType" />
        <p class="payment-method-text">{{ primaryPaymentMethod.cardType }} •••• {{ primaryPaymentMethod?.lastFourDigits }}</p>
      </div>
      <template v-else>
        <UIButton @click="emit('paymentMethod')" type="outline">Add payment method</UIButton>
        <div class="error-wrapper">
          <AlertCircle />
          <span class="error">Payment method required</span>
        </div>
      </template>
      <p class="details">
        Billing starts on {{ billingStart }}, the end of your free trial, and will renew automatically every month. You can cancel anytime.
      </p>
    </div>
    <div class="row">
      <h4 class="checkout-title">Total today</h4>
      <span class="total-price">{{ totalPrice }}</span>
    </div>
    <div class="footer">
      <UIButton :loading="loading" :disabled="disabled || !isPaymentMethodsExists" @click="emit('checkout')">Subscribe</UIButton>
      <p v-if="error" class="error">{{ error }}</p>
    </div>
  </UIModal>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import PremiumBuyerLabel from './PremiumBuyerLabel.vue';
import AlertCircle from '../assets/images/icons/alert-circle.svg';
import UIModal from '../../../shared/UIModal.vue';
import UIButton from '../../../shared/UIButton.vue';
import { usePaymentMethodsStore } from '../../payment-methods/stores/paymentMethods';
import CreditCardIcon from '../../payment-methods/components/CreditCardIcon.vue';

type SubscriptionPurchaseModalProps = {
  price: string
  billingStart: string
  trial?: boolean
  error?: string
  disabled: boolean
  loading: boolean
};

type SubscriptionPurchaseModalEmits = {
  checkout: []
  paymentMethod: []
};

const TRIAL_PRICE = `$0`;

const { billingStart, price, trial = false } = defineProps<SubscriptionPurchaseModalProps>();

const emit = defineEmits<SubscriptionPurchaseModalEmits>();

const isModalOpenModel = defineModel<boolean>('open', { required: true });

const { primaryPaymentMethod, isPaymentMethodsExists } = usePaymentMethodsStore();

const totalPrice = computed(() => trial ? TRIAL_PRICE : price);
</script>

<style scoped lang="scss">
@import '../assets/scss';

.modal {
  max-width: 520px;

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .price {
    color: var(--Text-Default, $--theme-text-default);
    @include text-15-700;
  }

  .error-wrapper {
    display: flex;
    align-items: center;
    gap: 3px;

    .error {
      color: var(--Alert-Danger, $--theme-danger-55-Danger);
      @include text-12-400;
    }
  }

  .total-price {
    color: var(--Text-Default, $--theme-text-default);
    @include text-16-700;
  }

  .payment-method-wrapper {
    display: flex;
    align-items: center;
    padding: 7px 8px;
    gap: 8px;

    border-radius: 6px;
    border: 1px solid var(--Gray-10-Light, $--theme-gray-10-Light);
    background: var(--White, $--theme-white);

    .payment-method-text {
      color: var(--Text-Default, $--theme-text-default);
      @include text-14-500;
    }
  }

  .payment-method-required {
    display: grid;
    gap: 6px;

    .details {
      color: var(--Text-Medium, $--theme-text-medium);
      @include text-12-400;
    }
  }

  .bill-title {
    color: var(--Text-Default, $--theme-text-default);
    @include text-15-500
  }

  .bill-start {
    color: var(--Text-Medium, $--theme-text-medium);
    @include text-14-400;
  }

  .checkout-title {
    color: var(--Text-Default, $--theme-text-default);
    @include text-16-400;
  }

  .error {
    color: var(--Alert-Danger, $--theme-danger-55-Danger);
    @include text-14-400;
    line-height: 20px;
    text-align: center;
  }

  .footer {
    display: grid;
    gap: 6px;
    width: 100%;
  }
}
</style>
