import { createCustomElement, createPlugin } from '../../util/helpers';
import Subscriptions from './components/Subscriptions.vue';
import SubscriptionPromoCheckout from './components/SubscriptionPromoCheckout.vue';

const
  SubscriptionsPlugin = createPlugin('Subscriptions', Subscriptions),
  SubscriptionPromoCheckoutPlugin = createPlugin('SubscriptionPromoCheckout', SubscriptionPromoCheckout),
  SubscriptionsElement = createCustomElement(Subscriptions),
  SubscriptionPromoCheckoutElement = createCustomElement(SubscriptionPromoCheckout);

export {
  Subscriptions,
  SubscriptionPromoCheckout,
  SubscriptionsPlugin,
  SubscriptionPromoCheckoutPlugin,
  SubscriptionsElement,
  SubscriptionPromoCheckoutElement,
};
