<template>
  <div class="property" @click.prevent="onPropertyClick">
    <div class="property__image">
      <img :src="props.thumb" />
    </div>
    <div class="property__content">
      <div class="property__title">
        <div class="property__price">${{ formatNumber(props.price) }}</div>
        <div class="property__subtitle" v-if="props.arv">(ARV - ${{ formatNumber(props.arv) }})</div>
        <div class="property__subtitle" v-else></div>
        <div class="property__date">
          <UIMessageStatusBadge class="property__date-badge">
            <template #label>{{ formatDate(props.date) }}</template>
          </UIMessageStatusBadge>
        </div>
      </div>
      <div class="property__address">
        <div class="property__address-icon"><IconLocation/></div>
        <div>{{ props.address }}</div>
      </div>
      <div class="property__details">
        <div class="property__details-item">
          <div class="property__details-item-icon"><IconBed/></div>
          <div class="property__details-item-text">{{ props.beds }} beds</div>
        </div>
        <div class="property__details-item">
          <div class="property__details-item-icon"><IconBath/></div>
          <div class="property__details-item-text">{{ props.baths }} baths</div>
        </div>
        <div class="property__details-item">
          <div class="property__details-item-icon"><IconCube/></div>
          <div class="property__details-item-text">{{ formatNumber(props.sqft) }} sq.ft</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import IconBath from '../assets/images/icons/bath.svg';
import IconBed from '../assets/images/icons/bed.svg';
import IconCube from '../assets/images/icons/cube.svg';
import IconLocation from '../assets/images/icons/location.svg';

import { UIMessageStatusBadge } from '@investorlift/www-investorlift-ui';
import moment from '../utils/moment';

type DateType = string | Date | moment.Moment;

const props = defineProps<{
  id: any
  date: DateType
  url: string
  thumb: string
  price: number
  arv: number | null
  address: string
  beds: number | string
  baths: number | string
  sqft: number
}>();

const formatDate = (date: DateType) => {
  const dt = moment(date);
  const today = moment().startOf('day');

  if (!dt.isValid()) return 'Recently';
  if (dt.isSame(today, 'day')) return dt.format('h:mm A');
  if (dt.isSame(today, 'year')) return dt.format('DD MMM');

  return dt.format('DD MMM, YYYY');
};
const formatNumber = (n: number | string) => {
  if (!n || typeof n === 'string') return n;
  return n.toLocaleString('en-US');
};

const onPropertyClick = () => {
  window.open(props.url, '_blank');
};
</script>

<style lang="scss" scoped>
.property {
  display: flex;
  justify-content: stretch;
  gap: 12px;
  width: 100%;
  min-height: 108px;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  padding: 12px;
  background-color: #FFFFFF;
  overflow: hidden;
  cursor: pointer;

  &__image {
    display: flex;
    flex: none;
    width: 84px;
    height: 84px;

    & > :deep(img) {
      width: 84px;
      height: 84px;
      border-radius: 4px;
      object-fit: cover;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 4px;
    flex: 1;
    overflow: hidden;
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__price {
    flex: none;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    color: #1A1B22;
  }

  &__subtitle {
    flex: auto;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    color: #1A1B22;
  }

  &__date {
    flex: none;

    &-badge {
      padding: 3px 8px;
      background-color: #F2F2F2 !important;
      color: #4F5366 !important;
    }
  }

  &__address {
    display: flex;
    align-items: center;
    gap: 2px;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    color: #1A1B22;

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 14px;
      height: 14px;

      & > svg {
        width: 14px;
        height: 14px;
      }
    }
  }

  &__details {
    display: flex;
    flex: auto;
    align-items: end;
    gap: 12px;

    &-item {
      display: flex;
      align-items: center;
      gap: 4px;

      &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18px;
        height: 18px;

        & > svg {
          width: 18px;
          height: 18px;
        }
      }

      &-text {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #4F5366;
      }
    }
  }
}
</style>
