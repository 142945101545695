import { createCustomElement, createPlugin } from '../../util/helpers';
import PaymentMethods from './components/PaymentMethods.vue';

const
  PaymentMethodsPlugin = createPlugin('PaymentMethods', PaymentMethods),
  PaymentMethodsElement = createCustomElement(PaymentMethods);

export {
  PaymentMethods,
  PaymentMethodsPlugin,
  PaymentMethodsElement,
};
