<template>
  <div class="messenger" v-if="isAuthorized">
    <div class="messenger__chat-panel messenger__chat-list">
      <ChatList/>
    </div>
    <div class="messenger__chat-panel messenger__chat-frame">
      <ChatFrame/>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PusherConfig, StringifiedPusherConfig } from '../stores/chat';
import { onMounted } from 'vue';
import ChatFrame from './ChatFrame.vue';
import ChatList from './ChatList.vue';
import { useUserStore } from '../stores/user';
import { controllerMount } from '../utils/controller';

interface MessengerProps {
  jwt: string
  api: string
  pusher: PusherConfig | StringifiedPusherConfig
}

const props = defineProps<MessengerProps>();

const { isAuthorized } = useUserStore();

onMounted(async () => {
  const { jwt, api, pusher } = props;

  await controllerMount(jwt, api, pusher);
});
</script>

<style lang="scss">
@import "../assets/scss/_index.scss";
</style>

<style lang="scss" scoped>
.messenger {
  display: flex;
  justify-content: center;
  gap: 24px;
  width: 100%;
  height: 100%;
  background-color: #FBFBFB;
  padding: 16px;

  :deep(*) {
    font-family: 'Inter', sans-serif;
  }

  &__chat-panel {
    height: 100%;
    background-color: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 12px;
  }

  &__chat-list {
    width: 320px;
    padding: 16px 0;
  }

  &__chat-frame {
    flex: 1;
    max-width: 1016px;
  }
}
</style>
