<template>
  <SubscriptionPromoModal
    :loading="isDataLoading"
    v-model:open="isSubscriptionPromoModalOpenModel"
    @subscribe="handleSubscribeClick"
  />
  <SubscriptionPurchaseModal
    v-if="premiumBuyerSubscription"
    trial
    :price="premiumBuyerSubscription.price"
    :billing-start="billingStart"
    :disabled="isCheckoutDisabled"
    :loading="isCheckoutDisabled"
    v-model:open="isCheckoutModalOpen"
    @payment-method="handleOpenAddPaymentMethodModal"
    @checkout="handleCheckout"
  />
  <AddPaymentMethodModalForm
    v-model:open="isAddPaymentMethodModalOpen"
    @submit="handlePaymentMethodSubmit"
  />
</template>

<script setup lang="ts">
import { computed, onBeforeMount, onBeforeUnmount, ref, watch } from 'vue';
import SubscriptionPromoModal from './SubscriptionPromoModal.vue';
import SubscriptionPurchaseModal from './SubscriptionPurchaseModal.vue';
import {
  type AvailableSubscription,
  getSubscriptions,
  init as subscriptionsInit,
  purchaseSubscription,
  SUBSCRIPTION_PLANS, update as subscriptionsUpdate,
} from '../api/api';
import AddPaymentMethodModalForm from '../../payment-methods/components/AddPaymentMethodModalForm.vue';
import { SUBSCRIPTION_EVENTS, useSubscriptionsStore } from '../stores/subscriptions';
import { getPaymentMethods, init as paymentMethodsInit, update as paymentMethodsUpdate } from '../../payment-methods/api/api';
import { usePaymentMethodsStore } from '../../payment-methods/stores/paymentMethods';
import formatBillDate from '../utils/formatBillDate';
import moment from '../../messenger/utils/moment';

type SubscriptionPromoCheckoutProps = {
  jwt: string
  api: string
};

const isSubscriptionPromoModalOpenModel = ref(false);

const props = defineProps<SubscriptionPromoCheckoutProps>();

const emit = defineEmits<{
  (e: SUBSCRIPTION_EVENTS.SUBSCRIPTION_PURCHASED): void
}>();

const { activeSubscriptions, availableSubscriptions } = useSubscriptionsStore();
const { paymentMethods } = usePaymentMethodsStore();

const isCheckoutModalOpen = ref(false);
const isCheckoutDisabled = ref(false);
const isAddPaymentMethodModalOpen = ref(false);
const premiumBuyerSubscription = ref<AvailableSubscription | null>(null);
const isDataLoading = ref(false);

const billingStart = computed(() => formatBillDate(moment().add(1, 'month')));

const handleSubscribeClick = async () => {
  isDataLoading.value = true;
  await handleDataFetch();
  isDataLoading.value = false;

  isSubscriptionPromoModalOpenModel.value = false;
  isCheckoutModalOpen.value = true;
};

const handlePaymentMethodSubmit = () => {
  const isPaymentMethodAdded = paymentMethods.value.length !== 0;

  if (isPaymentMethodAdded) {
    isCheckoutModalOpen.value = true;
  }
};

const handleCheckout = async () => {
  isCheckoutDisabled.value = true;

  const purchasedSubscription = await purchaseSubscription(SUBSCRIPTION_PLANS.DEALS_VIP);

  if (purchasedSubscription) {
    emit(SUBSCRIPTION_EVENTS.SUBSCRIPTION_PURCHASED);
    activeSubscriptions.value.push(purchasedSubscription);
  }

  isCheckoutModalOpen.value = false;
  isCheckoutDisabled.value = false;
};

const handleOpenAddPaymentMethodModal = () => {
  isCheckoutModalOpen.value = false;
  isAddPaymentMethodModalOpen.value = true;
};

const handleSubscriptionPromoModalOpen = () => {
  isSubscriptionPromoModalOpenModel.value = true;
};

const handleDataFetch = async () => {
  const [
    subscriptionsResponse,
    paymentMethodsResponse,
  ] = await Promise.all([
    getSubscriptions(),
    getPaymentMethods(),
  ]);

  if (paymentMethodsResponse) {
    paymentMethods.value = paymentMethodsResponse;
  }

  if (subscriptionsResponse) {
    availableSubscriptions.value = subscriptionsResponse;
    premiumBuyerSubscription.value = availableSubscriptions.value.find(
      sub => sub.plan === SUBSCRIPTION_PLANS.DEALS_VIP,
    ) as AvailableSubscription;
  }
};

onBeforeMount(async () => {
  subscriptionsInit({ jwt: props.jwt, api: props.api });
  paymentMethodsInit({ jwt: props.jwt, api: props.api });

  window.addEventListener(
    SUBSCRIPTION_EVENTS.OPEN_SUBSCRIPTION_PROMO_MODAL, handleSubscriptionPromoModalOpen,
  );
});

onBeforeUnmount(() => {
  window.removeEventListener(
    SUBSCRIPTION_EVENTS.OPEN_SUBSCRIPTION_PROMO_MODAL, handleSubscriptionPromoModalOpen,
  );
});

// watch for jwt changes, and update api if jwt is present
watch(
  () => props.jwt,
  async () => {
    const { jwt, api } = props;

    if (jwt) {
      subscriptionsUpdate({ jwt, api });
      paymentMethodsUpdate({ jwt, api });
    }
  },
);
</script>

<style>
@import '../assets/scss/_index.scss';
</style>

<style scoped lang="scss">
@import '../assets/scss';
</style>
