<template>
  <main class="payment-method-container">
    <div class="container">
      <header class="header">
        <h1 class="heading">Subscriptions</h1>
        <p class="sub-heading">Keep track of your subscriptions</p>
      </header>
      <div class="content">
        <template v-if="isActiveSubscriptionsExists">
          <ActiveSubscriptionCard
            trial
            v-for="activeSubscription in activeSubscriptions"
            :key="activeSubscription.id"
            :id="activeSubscription.id"
            :price="activeSubscription.price"
            :next-bill="billingStart"
            :auto-renew="activeSubscription.isAutoRenew"
          >
            <PremiumBuyerLabel />
          </ActiveSubscriptionCard>
        </template>
        <template  v-else>
          <EmptySubscriptions />
          <p class="headline">Available subscription</p>
          <AvailableSubscriptionCard
            v-for="subscription in availableSubscriptions"
            title="Upgrade to Premium to access all verified deals"
            button-text="Try free"
            :key="subscription.id"
            :details="`Enjoy a 1-month free trial, then ${subscription.price}`"
            :price="subscription.price"
            :plan="subscription.plan"
            @subscription-purchased="handleSubscription"
          >
            <PremiumBuyerLabel />
          </AvailableSubscriptionCard>
        </template>
      </div>
    </div>
  </main>
</template>

<script setup lang="ts">
import { computed, onBeforeMount } from 'vue';
import EmptySubscriptions from './EmptySubscriptions.vue';
import AvailableSubscriptionCard from './AvailableSubscriptionCard.vue';
import PremiumBuyerLabel from './PremiumBuyerLabel.vue';
import ActiveSubscriptionCard from './ActiveSubscriptionCard.vue';
import {
  getActiveSubscriptions,
  getSubscriptions, SUBSCRIPTION_PLANS,
} from '../api/api';
import { getPaymentMethods } from '../../payment-methods/api/api';
import { usePaymentMethodsStore } from '../../payment-methods/stores/paymentMethods';
import { SUBSCRIPTION_EVENTS, useSubscriptionsStore } from '../stores/subscriptions';
import moment from 'moment';
import formatBillDate from '../utils/formatBillDate';

const emit = defineEmits([SUBSCRIPTION_EVENTS.SUBSCRIPTION_PURCHASED]);

const { paymentMethods } = usePaymentMethodsStore();
const { availableSubscriptions, activeSubscriptions, isActiveSubscriptionsExists } = useSubscriptionsStore();

const billingStart = computed(() => formatBillDate(moment().add(1, 'month')));

const handleSubscription = (payload: SUBSCRIPTION_PLANS) => {
  emit(SUBSCRIPTION_EVENTS.SUBSCRIPTION_PURCHASED, payload);
};

onBeforeMount(async () => {
  const [
    subscriptionsResponse,
    paymentMethodsResponse,
    activeSubscriptionsResponse,
  ] = await Promise.all([
    getSubscriptions(),
    getPaymentMethods(),
    getActiveSubscriptions(),
  ]);

  if (activeSubscriptionsResponse) {
    activeSubscriptions.value = activeSubscriptionsResponse;
  }

  if (paymentMethodsResponse) {
    paymentMethods.value = paymentMethodsResponse;
  }

  if (subscriptionsResponse) {
    availableSubscriptions.value = subscriptionsResponse;
  }
});
</script>

<style>
@import '../assets/scss/_index.scss';
</style>

<style scoped lang="scss">
@import '../assets/scss';

.payment-method-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  .container {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;
    max-width: 1018px;

    border-radius: 12px;
    border: 1px solid $--theme-gray-10-Light;

    .header {
      width: 100%;
      padding: 16px 24px;

      border-bottom: 1px solid $--theme-gray-10-Light;

      .heading {
        color: var(--Text-Default, $--theme-text-default);
        @include text-18-600;
      }

      .sub-heading {
        color: var(--Text-Medium, $--theme-text-medium);
        @include text-13-400;
      }
    }

    .content {
      display: grid;
      width: 100%;
      height: 100%;
      padding: 16px 24px;
      gap: 16px;
      grid-auto-rows: max-content;

      .headline {
        margin-top: 24px;
        color: var(--Text-Default, $--theme-text-default);
        @include text-18-700;
      }
    }
  }
}
</style>
