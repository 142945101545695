<template>
  <form class="form" ref="form" @submit.prevent>
    <label class="input-block">
      First name
      <UIInput :disabled="Boolean(disabledFields?.firstName)" @input="handleFirstNameInput" :value="firstName" placeholder="John" />
      <span v-if="errors?.firstName" class="error-wrapper">
        <AlertIcon />
        <span class="error-text">{{ errors.firstName }}</span>
      </span>
    </label>
    <label class="input-block">
      Last name
      <UIInput :disabled="Boolean(disabledFields?.lastName)" @input="handleLastNameInput" :value="lastName" placeholder="Doe" />
      <span v-if="errors?.lastName" class="error-wrapper">
        <AlertIcon />
        <span class="error-text">{{ errors.lastName }}</span>
      </span>
    </label>
    <h2 class="sub-title span-col-2">
      Credit card details
    </h2>
    <label class="input-block span-col-2">
      Card number
      <UIInput
        :disabled="Boolean(disabledFields?.creditCardNumber)"
        ref="creditCardNumberInput"
        @input="handleCreditCardNumberInput"
        placeholder="1234 1234 1234 1234"
      />
      <span v-if="errors?.creditCardNumber" class="error-wrapper">
        <AlertIcon />
        <span class="error-text">{{ errors.creditCardNumber }}</span>
      </span>
    </label>
    <label class="input-block">
      Expiration date
      <UIInput
        :disabled="Boolean(disabledFields?.expirationMonth) || Boolean(disabledFields?.expirationYear)"
        ref="dateInput"
        @input="handleCreditCardDateInput"
        placeholder="MM/YY"
      />
      <span v-if="errors?.expirationMonth || errors?.expirationYear" class="error-wrapper">
        <AlertIcon />
        <span class="error-text">{{ errors?.expirationMonth || errors?.expirationYear }}</span>
      </span>
    </label>
    <label class="input-block">
      CVV
      <UIInput
        ref="cvvInput"
        :disabled="Boolean(disabledFields?.cvv)"
        @input="handleCreditCardCvvInput"
        placeholder="***"
      >
        <template #slotRight>
          <CreditCardCVV/>
        </template>
      </UIInput>
      <span v-if="errors?.cvv" class="error-wrapper">
        <AlertIcon />
        <span class="error-text">{{ errors?.cvv }}</span>
      </span>
    </label>
    <slot class="span-col-2" :isDirty="isDirty" :submit="handleSubmit" />
  </form>
</template>

<script setup lang="ts">
import { computed, ref, useTemplateRef, watch } from 'vue';
import CreditCardCVV from '../assets/images/icons/credit-card-cvv.svg';
import { UIInput } from '@investorlift/www-investorlift-ui';
import type { PaymentMethod, PaymentMethodDto } from '../api/api';
import AlertIcon from '../assets/images/icons/alert-circle.svg';

export type PaymentMethodDisabledFields = Partial<{
  firstName: boolean
  lastName: boolean
  creditCardNumber: boolean
  expirationYear: boolean
  expirationMonth: boolean
  cvv: boolean
}>;

type PaymentMethodFormProps = {
  errors: Partial<PaymentMethodDto>
  initialValues?: PaymentMethod
  disabledFields?: PaymentMethodDisabledFields
};

const { errors, initialValues, disabledFields } = defineProps<PaymentMethodFormProps>();

const form = useTemplateRef('form');
const dateInput = useTemplateRef('dateInput');
const creditCardNumberInput = useTemplateRef('creditCardNumberInput');
const cvvInput = useTemplateRef('cvvInput');

const firstName = ref('');
const lastName = ref('');
const creditCardNumber = ref('');
const expirationYear = ref('');
const expirationMonth = ref('');
const cvv = ref('');

watch(() => initialValues, () => {
  firstName.value = initialValues?.firstName ?? '';
  lastName.value = initialValues?.lastName ?? '';

  const dateInputEl: HTMLInputElement = dateInput.value?.$el.querySelector('input')!;
  dateInputEl.value = `${initialValues?.expirationMonth}/${initialValues?.expirationYear}`;

  const creditCardNumberEl: HTMLInputElement = creditCardNumberInput.value?.$el.querySelector('input')!;
  creditCardNumberEl.value = `**** **** **** ${initialValues?.lastFourDigits}`;

  const cvvEl = cvvInput.value?.$el.querySelector('input')!;
  cvvEl.value = '***';
});

const REMOVE_NON_DIGITS_REGEXP = /[^0-9]/g;
const REMOVE_ALL_SPACES_REGEXP = /\s+/g;
const MATCH_EVERY_FOURTH_CHAR_REGEXP = /(\d{4})/g;
const CREDIT_CARD_NUMBER_CHARS = 16;

const REMOVE_ALL_NON_NUMERIC_CHARS_EXCEPT_SLASH_REGEXP = /[^0-9/]/g;
const REMOVE_INCORRECT_SLASH_REGEXP = /\/+/g;
const CREDIT_CARD_DATE_MAX_CHARS = 5;
const SLASH_POSITION = 2;
const CVV_MAX_NUM = 4;

const handleFirstNameInput = (e: MouseEvent) =>
  firstName.value = (e.target as HTMLInputElement).value;

const handleLastNameInput = (e: MouseEvent) =>
  lastName.value = (e.target as HTMLInputElement).value;

const handleCreditCardNumberInput = (e: InputEvent) => {
  const target = e.target as HTMLInputElement;

  const actualValue = target.value
    .replace(REMOVE_NON_DIGITS_REGEXP, '')
    .replace(REMOVE_ALL_SPACES_REGEXP, '')
    .substring(0, CREDIT_CARD_NUMBER_CHARS)
    .trim();

  creditCardNumber.value = actualValue;
  target.value = actualValue.replace(MATCH_EVERY_FOURTH_CHAR_REGEXP, '$1 ').trim();
};

const handleCreditCardDateInput = (e: InputEvent) => {
  const target = e.target as HTMLInputElement;
  const maxCharsWithoutSlash = CREDIT_CARD_DATE_MAX_CHARS - 1;

  let value = target.value
    .replace(REMOVE_ALL_NON_NUMERIC_CHARS_EXCEPT_SLASH_REGEXP, '')
    .replace(REMOVE_INCORRECT_SLASH_REGEXP, '')
    .slice(0, maxCharsWithoutSlash);

  if (value.length > SLASH_POSITION) {
    const month = value.substring(0, SLASH_POSITION);
    const year = value.substring(SLASH_POSITION);
    value = `${month}/${year}`;

    expirationYear.value = year;
    expirationMonth.value = month;
  }

  target.value = value.substring(0, CREDIT_CARD_DATE_MAX_CHARS);
};

const handleCreditCardCvvInput = (e: InputEvent) => {
  const target = e.target as HTMLInputElement;

  const isNumAdded = target.value.length > cvv.value.length;
  const isLimitNotExceeded = cvv.value.length !== CVV_MAX_NUM;

  if (isNumAdded && isLimitNotExceeded) {
    cvv.value += target.value.replace(REMOVE_NON_DIGITS_REGEXP, '');
  }
  else if (!isNumAdded) {
    const diff = cvv.value.length - target.value.length;
    cvv.value = cvv.value.slice(0, -diff);
  }

  target.value = '*'.repeat(cvv.value.length);
};

const handleResetForm = () => {
  form.value?.reset();
  firstName.value = '';
  lastName.value = '';
};

const handleSubmit = (cb: (formData: Partial<PaymentMethodDto>, reset: () => void) => void) => {
  const formData: Partial<PaymentMethodDto> = {
    ...(firstName.value && { firstName: firstName.value }),
    ...(lastName.value && { lastName: lastName.value }),
    ...(creditCardNumber.value && { creditCardNumber: creditCardNumber.value }),
    ...(expirationYear.value && { expirationYear: expirationYear.value }),
    ...(expirationMonth.value && { expirationMonth: expirationMonth.value }),
    ...(cvv.value && { cvv: cvv.value }),
  };

  cb(formData, handleResetForm);
};

const isDirty = computed(() =>
  !firstName.value
  || !lastName.value
  || !creditCardNumber.value
  || !expirationYear.value
  || !expirationMonth.value
  || !cvv.value,
);
</script>

<style scoped lang="scss">
@import '../assets/scss';

.span-col-2 {
  grid-column: span 2;
}

.sub-title {
  @include text-18-500;
  color: $--theme-text-default;
}

.form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  flex-direction: column;
  align-items: flex-start;

  column-gap: 8px;
  row-gap: 24px;

  :deep(.has-slot-right) {
    align-items: center;
    padding-right: 12px;
  }

  .input-block {
    display: grid;
    gap: 8px;
    width: 100%;

    color: $--theme-text-medium;
    @include text-14-400;

    .error-wrapper {
      display: flex;
      align-items: center;
      gap: 3px;

      .error-text {
        color: var(--Alert-Danger, $--theme-danger-55-Danger);
        @include text-12-400;
      }
    }
  }
}
</style>
