import axios from 'axios';
import { computed, ref } from 'vue';

class UserStore {
  public token = ref('');
  public user = ref(null);
  public isAuthorized = computed(() => !!this.token.value);

  public authorize = async (jwt: string, baseURL: string) => {
    if (!jwt) {
      console.warn('No valid token provided');
      return;
    }

    try {
      const response = await axios.request({
        baseURL,
        url: '/auth/user',
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });

      this.token.value = jwt;
      this.user.value = response.data?.data;
    }
    catch (e) {
      console.warn('Auth error', e);
    }
  };

  public logout = () => {
    this.token.value = '';
    this.user.value = null;
  };
}

const userStore = new UserStore();

export function useUserStore() {
  return userStore;
}
