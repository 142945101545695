import moment from 'moment-timezone';

const LOCALE_DEFAULT = 'en';
const TIMEZONE_DEFAULT = 'America/New_York';

moment.locale(LOCALE_DEFAULT);
moment.tz.setDefault(TIMEZONE_DEFAULT);

export const resolveTimezone = (date: any) => {
  const timezone = moment.tz.guess();

  if (timezone) return moment(date).tz(timezone);
  return moment(date);
};

export default moment;
