<script setup lang="ts">
import SubscriptionsPage from './SubscriptionsPage.vue';
import ModalProvider from '../../../shared/providers/ModalProvider.vue';
import { init as initSubscriptionsApi, SUBSCRIPTION_PLANS } from '../api/api';
import { init as initPaymentMethodsApi } from '../../payment-methods/api/api';
import { SUBSCRIPTION_EVENTS } from '../stores/subscriptions';

type SubscriptionsProps = {
  jwt: string
  api: string
};

const { jwt, api } = defineProps<SubscriptionsProps>();

const emit = defineEmits([SUBSCRIPTION_EVENTS.SUBSCRIPTION_PURCHASED]);

const handleSubscription = (payload: SUBSCRIPTION_PLANS) => {
  emit(SUBSCRIPTION_EVENTS.SUBSCRIPTION_PURCHASED, payload);
};

initSubscriptionsApi({
  jwt,
  api,
});

initPaymentMethodsApi({
  jwt,
  api,
});
</script>

<template>
<ModalProvider>
  <SubscriptionsPage @subscription-purchased="handleSubscription" />
</ModalProvider>
</template>

<style scoped lang="scss">

</style>
