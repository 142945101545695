<template>
  <UIModal v-model:open="isModalOpenModel" class="modal">
    <template #header-icon>
      <PremiumBuyerLabel class="premium-buyer-label" />
    </template>
    <div class="content">
      <EmptySubscriptions height="180px" width="180px" />
      <div class="text">
        <h2 class="heading">Upgrade to Premium to access all verified deals</h2>
        <p class="sub-heading">Enjoy a 1-month free trial, then $19.99/month</p>
      </div>
      <UIButton
        :loading="loading"
        :disabled="loading"
        type="filled"
        class="subscribe-button"
        @click="emit('subscribe')"
      >
        Try free
      </UIButton>
    </div>
  </UIModal>
</template>

<script setup lang="ts">
import UIModal from '../../../shared/UIModal.vue';
import EmptySubscriptions from '../assets/images/empty-subscriptions.svg';
import UIButton from '../../../shared/UIButton.vue';
import PremiumBuyerLabel from './PremiumBuyerLabel.vue';

const isModalOpenModel = defineModel<boolean>('open', { required: true });

defineProps<{
  loading: boolean
}>();

const emit = defineEmits<{
  subscribe: []
}>();
</script>

<style scoped lang="scss">
@import '../assets/scss';

.modal {
  width: 520px;

  .premium-buyer-label {
    margin-left: 8px;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;

    .text {
      display: flex;
      flex-direction: column;
      gap: 4px;
      text-align: center;

      .heading {
        @include text-24-700;
      }

      .sub-heading {
        @include text-16-400;
      }

      .heading,
      .sub-heading {
        color: var(--Text-Default, $--theme-text-default);
        text-align: center;
      }
    }

    .subscribe-button {
      width: 100%;
    }
  }
}
</style>
