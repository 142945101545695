<template>
  <div class="payment-method">
    <UIRadioInputField checked value="" name="payment-method" />
    <CreditCardIcon class="row-span-2" :type="data.cardType" />
    <h4 class="heading">{{ data.cardType }}</h4>
    <p class="card-number">Credit card ending in •••• {{ data.lastFourDigits}}</p>
    <UIButton
      type="plain"
      class="row-span-2 edit-button"
      @click="emit('edit')"
    >
      Edit
    </UIButton>
  </div>
</template>

<script setup lang="ts">
import { UIRadioInputField } from '@investorlift/www-investorlift-ui';
import UIButton from '../../../shared/UIButton.vue';
import { type PaymentMethod } from '../api/api';
import CreditCardIcon from './CreditCardIcon.vue';

type PaymentMethodProps = {
  data: PaymentMethod
};

type PaymentMethodEmits = {
  edit: []
};

const { data } = defineProps<PaymentMethodProps>();

const emit = defineEmits<PaymentMethodEmits>();
</script>

<style scoped lang="scss">
@import '../assets/scss';

.row-span-2 {
  grid-row: span 2;
}

.payment-method {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-auto-flow: column;
  grid-template-columns: repeat(3, max-content) 1fr;
  justify-content: start;
  align-items: center;
  column-gap: 16px;
  width: 100%;

  padding: 24px;

  border-radius: 8px;
  border: 1px solid $--theme-blue-20-Light;

  :deep(.ui-radio-input) {
    grid-row: span 2;
  }

  .heading {
    color: $--theme-text-default;
    @include text-16-500
  }

  .card-number {
    color: $--theme-text-medium;
    @include text-14-400
  }

  .edit-button {
    justify-self: end;
  }
}
</style>
