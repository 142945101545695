import { computed, ref } from 'vue';
import type { PaymentMethodsList } from '../api/api';

class PaymentMethodsStore {
  public paymentMethods = ref<PaymentMethodsList>([]);
  public isPaymentMethodsExists = computed(() => this.paymentMethods.value.length !== 0);
  public primaryPaymentMethod = computed(() => this.paymentMethods.value.find(method => method.isPrimary));
}

const paymentMethodsStore = new PaymentMethodsStore();

export function usePaymentMethodsStore() {
  return paymentMethodsStore;
}
