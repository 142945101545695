<template>
  <template v-if="!LabelDictionary.has(type as StatusType)">
    <slot></slot>
  </template>
  <template v-else>
    <UIMessageStatusBadge :class="['status-badge', `status-badge--${type}`]">
      <template #label>{{ LabelDictionary.get(type as StatusType) }}</template>
    </UIMessageStatusBadge>
  </template>
</template>

<script setup lang="ts">
import { UIMessageStatusBadge } from '@investorlift/www-investorlift-ui';

enum StatusType {
  MESSAGE = 'message',
  ADDRESS_REQUEST = 'address_request',
  BLAST = 'blast',
  DEALS_DIGEST = 'deals_digest',
  INQUIRY = 'inquiry',
  OFFER = 'offer',
  SMS = 'sms',
  EMAIL = 'email',
}

const LabelDictionary = new Map([
  [StatusType.ADDRESS_REQUEST, 'Address request'],
  [StatusType.BLAST, 'Blast'],
  [StatusType.DEALS_DIGEST, 'Deals digest'],
  [StatusType.INQUIRY, 'Sent an inquiry'],
  [StatusType.OFFER, 'Made an offer'],
  [StatusType.SMS, 'SMS'],
  [StatusType.EMAIL, 'Email'],
]);

const {
  type = StatusType.MESSAGE,
} = defineProps<{
  type?: string | null | StatusType
}>();
</script>

<style lang="scss" scoped>
.status-badge {
  padding: 4px 8px;

  &--blast, &--deals_digest {
    background-color: #D4F6D4 !important;
    color: #00B200 !important;
  }

  &--inquiry, &--address_request {
    background-color: #F2F2F2 !important;
    color: #1A1B22 !important;
  }

  &--offer {
    background-color: #D4DEFF !important;
    color: #2958FF !important;
  }

  &--sms, &--email {
    background-color: #F2F2F2 !important;
    color: #999999 !important;
  }
}
</style>
