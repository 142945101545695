<template>
  <MastercardIcon v-if="isMastercard" />
  <VisaIcon v-else-if="isVisa" />
  <AmericanExpressIcon v-else-if="isAmericanExpress" />
  <DinnersClubIcon v-else-if="isDinnersClub" />
  <JcbIcon v-else-if="isJcb" />
  <DiscoverIcon v-else-if="isDiscover" />
  <UnknownCardIcon v-else />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { CREDIT_CARD_TYPES } from '../api/api';
import UnknownCardIcon from '../assets/images/unknown-card.svg';
import JcbIcon from '../assets/images/jcb.svg';
import DinnersClubIcon from '../assets/images/dinners-club.svg';
import MastercardIcon from '../assets/images/mastercard.svg';
import DiscoverIcon from '../assets/images/discover.svg';
import VisaIcon from '../assets/images/visa.svg';
import AmericanExpressIcon from '../assets/images/american-express.svg';

type CreditCardIconProps = {
  type: CREDIT_CARD_TYPES
};

const { type } = defineProps<CreditCardIconProps>();

const isVisa = computed(() => type === CREDIT_CARD_TYPES.VISA);
const isMastercard = computed(() => type === CREDIT_CARD_TYPES.MASTERCARD);
const isAmericanExpress = computed(() => type === CREDIT_CARD_TYPES.AMERICAN_EXPRESS);
const isDinnersClub = computed(() => type === CREDIT_CARD_TYPES.DINNERS_CARD);
const isJcb = computed(() => type === CREDIT_CARD_TYPES.JCB);
const isDiscover = computed(() => type === CREDIT_CARD_TYPES.DISCOVER);
</script>

<style scoped lang="scss">

</style>
