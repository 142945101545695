<template>
  <div class="subscription-card">
    <div class="header">
      <slot />
      <UIBadge status="success">Active</UIBadge>
    </div>
    <h3 class="heading">{{ subscriptionPrice }}</h3>
    <div class="content">
      <p v-if="nextBill" class="text-light">{{ billLabel }}</p>
      <time v-if="nextBill" :datetime="nextBill" class="bill-date">{{ nextBill }}</time>
      <span v-if="trial" class="price">{{ price }}</span>
      <div class="renew-subscription">
        <span class="text-light">auto-renew subscription</span>
        <UISwitch :disabled="isSwitchDisabled" :clickable="false" :switched="isAutoRenewEnabled" @click="handleModalConfirm" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import UIBadge from '../../../shared/UIBadge.vue';
import UISwitch from '../../../shared/UISwitch.vue';
import useModal from '../../../shared/composables/modal';
import { cancelSubscription, reactivateSubscription } from '../api/api';

type AvailableSubscriptionCardProps = {
  id: string
  price: string
  nextBill?: string
  trial?: boolean
  autoRenew?: boolean
};

const { id, trial, price, autoRenew = false } = defineProps<AvailableSubscriptionCardProps>();

const modal = useModal();

const isAutoRenewEnabled = ref(autoRenew);
const isSwitchDisabled = ref(false);

const subscriptionPrice = computed(() => trial ? 'Free trial' : price);

const billLabel = computed(() => trial ? 'Billing starts' : 'Renew at');

const modalOkText = computed(() => isAutoRenewEnabled.value ? 'Yes, turn it off' : 'Yes, turn it on');

const modalCancelText = computed(() => isAutoRenewEnabled.value ? 'No, keep it on' : 'No, keep it off');

const modalTitle = computed(() => isAutoRenewEnabled.value ? 'Turn off auto-renew' : 'Turn on auto-renew');

const modalContent = computed(() => isAutoRenewEnabled.value ? 'Are you sure you want to turn off auto-renew?' : 'Are you sure you want to turn on auto-renew?');

const handleModalConfirm = async () => {
  const result = await modal.confirm({
    title: modalTitle.value,
    content: modalContent.value,
    cancelText: modalCancelText.value,
    confirmText: modalOkText.value,
  });

  const isAutoEnableOn = result && isAutoRenewEnabled.value;
  const isAutoEnableOff = result && !isAutoRenewEnabled.value;

  isSwitchDisabled.value = true;

  if (isAutoEnableOn) {
    await cancelSubscription(id);
  }

  if (isAutoEnableOff) {
    await reactivateSubscription(id);
  }

  if (result) {
    isAutoRenewEnabled.value = !isAutoRenewEnabled.value;
  }

  isSwitchDisabled.value = false;
};
</script>

<style scoped lang="scss">
@import '../assets/scss';

.subscription-card {
  display: grid;
  gap: 16px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid var(--Blue-10-Light, $--theme-blue-10-Light);
  background-color: var(--White, $--theme-white);

  .text-light {
    color: var(--Text-Medium, $--theme-text-medium);
    @include text-14-400;
  }

  .bill-date {
    grid-column: 1 / 2;
    color: var(--Text-Medium, $--theme-text-medium);
    @include text-14-600;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .heading {
    margin-top: 8px;
    color: var(--Text-Default, $--theme-text-default);
    @include text-24-700;
  }

  .content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 6px;

    .renew-subscription {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      justify-self: end;
    }
  }

  .price {
    grid-column: 1 / 2;
    color: var(--Text-Default, $--theme-text-default);
    @include text-15-700;
  }
}
</style>
