<template>
  <UIModal v-model:open="model" class="modal" title="Add payment method">
    <PaymentMethodForm :errors="errors" v-slot="{ isDirty, submit }">
      <UIButton
        class="submit-button"
        :loading="isSubmitDisabled"
        :disabled="isDirty || isSubmitDisabled"
        @click="submit(handleSubmitForm)"
      >
        Next
      </UIButton>
    </PaymentMethodForm>
  </UIModal>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import UIModal from '../../../shared/UIModal.vue';
import PaymentMethodForm from './PaymentMethodForm.vue';
import UIButton from '../../../shared/UIButton.vue';
import { type PaymentMethodDto, publishPaymentMethod } from '../api/api';
import { usePaymentMethodsStore } from '../stores/paymentMethods';

const model = defineModel<boolean>('open', { required: true });

const emit = defineEmits<{
  submit: []
}>();

const { paymentMethods } = usePaymentMethodsStore();

const errors = ref<Partial<PaymentMethodDto>>({});
const isSubmitDisabled = ref(false);

const handleModalClose = () => model.value = false;

const handleSubmitForm = async (formData: Partial<PaymentMethodDto>, reset: () => void) => {
  isSubmitDisabled.value = true;

  const response = await publishPaymentMethod(formData);

  isSubmitDisabled.value = false;

  if (response && !('errors' in response)) {
    paymentMethods.value = [response];
  }

  emit('submit');

  if (!response) return;

  if ('errors' in response) {
    errors.value = response.errors;
    return;
  }

  reset();
  handleModalClose();
};
</script>

<style scoped lang="scss">
@import '../assets/scss';

.modal {
  width: 520px;

  .submit-button {
    width: 100%;
    grid-column: span 2;
  }
}
</style>
