import axios, { type AxiosInstance } from 'axios';

export enum CREDIT_CARD_TYPES {
  VISA = 'Visa',
  MASTERCARD = 'MasterCard',
  AMERICAN_EXPRESS = 'American Express',
  DINNERS_CARD = 'Diners Club',
  JCB = 'JCB',
  DISCOVER = 'Discover',
}

type ApiConfig = {
  jwt: string
  api: string
};

type PaymentMethodRaw = {
  id: string
  primary_payment_method: boolean
  backup_payment_method: boolean
  first_name: string
  last_name: string
  valid: boolean
  vat_number: number | null
  address: {
    phone: string | null
    street1: string | null
    street2: string | null
    city: string | null
    postal_code: number | null
    country: string | null
    region: string | null
  }
  company: string | null
  payment_method: {
    card_type: CREDIT_CARD_TYPES
    first_six: string
    last_four: string
    last_two: string | null
    exp_month: number
    exp_year: number
  }
};

type PaymentMethodsResponse = Readonly<PaymentMethodRaw[]>;

type PaymentMethodDtoRaw = {
  first_name: string
  last_name: string
  number: string
  month: string
  year: string
  cvv: string
};

export type PaymentMethodDto = {
  firstName: string
  lastName: string
  creditCardNumber: string
  expirationYear: string
  expirationMonth: string
  cvv: string
};

export type PaymentMethod = {
  id: PaymentMethodRaw['id']
  isPrimary: boolean
  cardType: CREDIT_CARD_TYPES
  lastFourDigits: PaymentMethodRaw['payment_method']['last_four']
  firstName: PaymentMethodRaw['first_name']
  lastName: PaymentMethodRaw['last_name']
  expirationYear: string
  expirationMonth: string
};

export type PaymentMethodsList = PaymentMethod[];

class Api {
  private axios: null | AxiosInstance = null;

  private token: string = '';
  private baseUrl: string = '';
  private isInitialized: boolean = false;

  public init = (config: ApiConfig) => {
    if (this.isInitialized) return;

    this.isInitialized = true;
    this.token = config.jwt;
    this.baseUrl = config.api;

    this.initJobs();
  };

  public update = (config: ApiConfig) => {
    this.token = config.jwt;
    this.baseUrl = config.api;
    this.initJobs();
  };

  private initJobs = () => {
    this.axios = axios.create({
      baseURL: this.baseUrl,
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    });
  };

  private transformPaymentMethodsResponse = (data: PaymentMethodsResponse): PaymentMethodsList => {
    return data.map(paymentMethod => ({
      id: paymentMethod.id,
      isPrimary: paymentMethod.primary_payment_method,
      firstName: paymentMethod.first_name,
      lastName: paymentMethod.last_name,
      cardType: paymentMethod.payment_method.card_type,
      lastFourDigits: paymentMethod.payment_method.last_four,
      expirationMonth: paymentMethod.payment_method.exp_month.toString().padStart(2, '0'),
      expirationYear: paymentMethod.payment_method.exp_year.toString().slice(2),
    }));
  };

  private transformPublishPaymentMethodsErrorResponse = (data: Partial<PaymentMethodDtoRaw>): Partial<PaymentMethodDto> => {
    const firstName = data?.first_name?.[0];
    const lastName = data?.last_name?.[0];
    const creditCardNumber = data?.number?.[0];
    const cvv = data?.cvv?.[0];
    const expirationYear = data?.year?.[0];
    const expirationMonth = data?.month?.[0];

    return {
      ...(firstName && { firstName }),
      ...(lastName && { lastName }),
      ...(creditCardNumber && { creditCardNumber }),
      ...(cvv && { cvv }),
      ...(expirationYear && { expirationYear }),
      ...(expirationMonth && { expirationMonth }),
    };
  };

  private preparePaymentMethodDto = (data: Partial<PaymentMethodDto>): Partial<PaymentMethodDtoRaw> => {
    return {
      ...(data.firstName && { first_name: data.firstName }),
      ...(data.lastName && { last_name: data.lastName }),
      ...(data.cvv && { cvv: data.cvv }),
      ...(data.creditCardNumber && { number: data.creditCardNumber }),
      ...(data.expirationYear && { year: data.expirationYear }),
      ...(data.expirationMonth && { month: data.expirationMonth }),
    };
  };

  public getPaymentMethods = async () => {
    const url = '../../api/recurly-accounts/billing-infos/all';

    try {
      const response = await this.axios?.get(url) || null;
      return this.transformPaymentMethodsResponse(response?.data);
    }
    catch (e) {
      console.warn('Something went wrong fetching payment methods', e);
    }
  };

  public getPaymentMethodById = async (id: string) => {
    const url = `../../api/recurly-accounts/billing-infos/${id}/item`;

    try {
      const response = await this.axios?.get(url) || null;
      return this.transformPaymentMethodsResponse([response?.data])[0];
    }
    catch (e) {
      console.warn('Something went wrong fetching payment method by id', e);
    }
  };

  public publishPaymentMethod = async (paymentMethodDto: Partial<PaymentMethodDto>) => {
    const url = '../../api/recurly-accounts/billing-infos/all';
    const rawData = this.preparePaymentMethodDto(paymentMethodDto);

    try {
      const response = await this.axios?.post(url, rawData) || null;
      return this.transformPaymentMethodsResponse([response?.data])[0];
    }
    catch (e) {
      if (axios.isAxiosError(e)) {
        return {
          errors: this.transformPublishPaymentMethodsErrorResponse(e?.response?.data.errors),
        };
      }

      console.warn('Something went wrong publishing payment method', e);
    }
  };

  public editPaymentMethod = async (paymentMethodDto: Partial<PaymentMethodDto>) => {
    const url = '../../api/recurly-accounts/billing-info';
    const rawData = this.preparePaymentMethodDto(paymentMethodDto);

    try {
      const response = await this.axios?.put(url, rawData) || null;
      return this.transformPaymentMethodsResponse([response?.data])[0];
    }
    catch (e) {
      if (axios.isAxiosError(e)) {
        return {
          errors: this.transformPublishPaymentMethodsErrorResponse(e?.response?.data.errors),
        };
      }

      console.warn('Something went wrong editing payment method', e);
    }
  };
}

export const {
  getPaymentMethods,
  publishPaymentMethod,
  editPaymentMethod,
  getPaymentMethodById,
  init,
  update,
} = new Api();
