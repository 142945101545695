<template>
  <UIModal v-model:open="open" :title="title" class="modal" @closed="handleCancel">
    <p class="content">{{ content }}</p>
    <div class="footer">
      <UIButton @click="handleCancel" as="gray" type="grey">
        {{ cancelText }}
      </UIButton>
      <UIButton @click="handleConfirm" as="red" type="tinted">
        {{ confirmText }}
      </UIButton>
    </div>
  </UIModal>
  <slot />
</template>

<script setup lang="ts">
import { provide, ref } from 'vue';
import { ModalConfirmKey } from '../providers/keys';
import UIButton from '../UIButton.vue';
import UIModal from '../UIModal.vue';

type ConfirmArgs = {
  title?: string
  content?: string
  confirmText?: string
  cancelText?: string
  onConfirm?: () => void
  onCancel?: () => void
};

type UserAction = (value: boolean) => void;

export type ConfirmModal = (args: ConfirmArgs) => Promise<boolean>;

const open = ref(false);
const title = ref('');
const content = ref('');
const confirmText = ref('');
const cancelText = ref('');

let userAction: UserAction;

const handleCancel = () => {
  userAction(false);
};

const handleConfirm = () => {
  userAction(true);
};

const confirm: ConfirmModal = async ({
  title: titleVal = '',
  content: contentVal = '',
  confirmText: confirmTextVal = '',
  cancelText: cancelTextVal = '',
  onConfirm,
  onCancel,
}: ConfirmArgs) => {
  open.value = true;
  title.value = titleVal;
  content.value = contentVal;
  confirmText.value = confirmTextVal;
  cancelText.value = cancelTextVal;

  return new Promise(
    resolve =>
      userAction = (value: boolean) => {
        if (value) {
          onConfirm?.();
        }
        else {
          onCancel?.();
        }

        resolve(value);
        open.value = false;
      },
  );
};

provide(ModalConfirmKey, confirm);
</script>

<style scoped lang="scss">
@import '../../assets/scss/variables';
@import '../../assets/scss/mixins';

.modal {
  width: 360px;

  .content {
    grid-column: span 2;
    color: var(--Text-Default, $--theme-text-default);
    @include text-18-700;
    text-align: center;
  }

  .footer {
    display: flex;
    grid-column: span 2;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
}
</style>
