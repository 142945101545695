import { inject } from 'vue';
import { ModalConfirmKey } from '../providers/keys';

const useModal = () => {
  const confirm = inject(ModalConfirmKey);

  if (!confirm) {
    throw new Error('Modal confirm is not being provided!');
  }

  return {
    confirm,
  };
};

export default useModal;
